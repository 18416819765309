import React, { useState } from "react"
import {graphql, useStaticQuery} from "gatsby"

import coachStyles from './coach_table.module.scss'
import SortableTable from "../table"
import TopTableBar from "../Extras/top_bar"


const CoachTable = () => {

    // temp fillers for testing //
    const tempFields = [
        {
            'displayName' : 'Seasons',
            'refName' : 'seasons',
            'fieldGroup' : 'Coach Info',
            'displayType' : 'AsInt',
            'displayParams' : {},
        },
        {
            'displayName' : 'Games',
            'refName' : 'games',
            'fieldGroup' : 'Coach Info',
            'displayType' : 'AsInt',
            'displayParams' : {},
        },
        {
            'displayName' : 'Teams',
            'refName' : 'teams',
            'fieldGroup' : 'Coach Info',
            'displayType' : 'TeamArray',
            'displayParams' : {},
        },
        {
            'displayName' : 'Super Bowls',
            'refName' : 'wins_superbowl',
            'fieldGroup' : 'Coach Info',
            'displayType' : 'ImageX',
            'displayParams' : {'imageType' : 'lombardi'},
        },
        {
            'displayName' : 'Conf. Champ',
            'refName' : 'games_superbowl',
            'fieldGroup' : 'Coach Info',
            'displayType' : 'ImageX',
            'displayParams' : {'imageType' : 'conf'},
        },
        {
            'displayName' : 'Playoff Berths',
            'refName' : 'playoff_births',
            'fieldGroup' : 'Coach Info',
            'displayType' : 'ImageX',
            'displayParams' : {'imageType' : 'playoff'},
        },
        {
            'displayName' : 'Status',
            'refName' : 'is_active',
            'fieldGroup' : 'Coach Info',
            'displayType' : 'ActiveStatus',
            'displayParams' : {},
        },
        {
            'displayName' : 'Wins',
            'refName' : 'wins',
            'fieldGroup' : 'Record',
            'displayType' : 'AsInt',
            'displayParams' : {},
        },
        {
            'displayName' : 'Losses',
            'refName' : 'losses',
            'fieldGroup' : 'Record',
            'displayType' : 'AsInt',
            'displayParams' : {},
        },
        {
            'displayName' : 'Ties',
            'refName' : 'ties',
            'fieldGroup' : 'Record',
            'displayType' : 'AsInt',
            'displayParams' : {},
        },
        {
            'displayName' : 'Win %',
            'refName' : 'win_pct',
            'fieldGroup' : 'Record',
            'displayType' : 'AsPct',
            'displayParams' : {'percision' : 1},
        },
        {
            'displayName' : 'ATS %',
            'refName' : 'ats_pct',
            'fieldGroup' : 'Against the Spread',
            'displayType' : 'AsPct',
            'displayParams' : {'percision' : 1},
        },
        {
            'displayName' : 'Return (Units)',
            'refName' : 'ats_return',
            'fieldGroup' : 'Against the Spread',
            'displayType' : 'AsInt',
            'displayParams' : {},
        },
        {
            'displayName' : 'Return (%)',
            'refName' : 'ats_roi',
            'fieldGroup' : 'Against the Spread',
            'displayType' : 'AsPct',
            'displayParams' : {'percision' : 1},
        },
        {
            'displayName' : 'Avg PF',
            'refName' : 'avg_pf',
            'fieldGroup' : 'Margin of Victory',
            'displayType' : 'AsFloat',
            'displayParams' : {'percision' : 1},
        },
        {
            'displayName' : 'Avg PA',
            'refName' : 'avg_pa',
            'fieldGroup' : 'Margin of Victory',
            'displayType' : 'AsFloat',
            'displayParams' : {'percision' : 1},
        },
        {
            'displayName' : 'Avg Margin',
            'refName' : 'avg_margin',
            'fieldGroup' : 'Margin of Victory',
            'displayType' : 'AsFloat',
            'displayParams' : {'percision' : 1},
        },
        {
            'displayName' : 'Avg Spread',
            'refName' : 'avg_spread',
            'fieldGroup' : 'Margin of Victory',
            'displayType' : 'AsFloat',
            'displayParams' : {'percision' : 1},
        },
        {
            'displayName' : 'Home',
            'refName' : 'ats_pct_home',
            'fieldGroup' : 'ATS Splits',
            'displayType' : 'BettingAts',
            'displayParams' : {'percision' : 1},
        },
        {
            'displayName' : 'Away',
            'refName' : 'ats_pct_away',
            'fieldGroup' : 'ATS Splits',
            'displayType' : 'BettingAts',
            'displayParams' : {'percision' : 1},
        },
        {
            'displayName' : 'Favorite',
            'refName' : 'ats_pct_favorite',
            'fieldGroup' : 'ATS Splits',
            'displayType' : 'BettingAts',
            'displayParams' : {'percision' : 1},
        },
        {
            'displayName' : 'Dog',
            'refName' : 'ats_pct_underdog',
            'fieldGroup' : 'ATS Splits',
            'displayType' : 'BettingAts',
            'displayParams' : {'percision' : 1},
        },
        {
            'displayName' : 'Off a Bye',
            'refName' : 'ats_pct_bye',
            'fieldGroup' : 'ATS Splits',
            'displayType' : 'BettingAts',
            'displayParams' : {'percision' : 1},
        },
        {
            'displayName' : 'Division',
            'refName' : 'ats_pct_div',
            'fieldGroup' : 'ATS Splits',
            'displayType' : 'BettingAts',
            'displayParams' : {'percision' : 1},
        },
        {
            'displayName' : 'Non Division',
            'refName' : 'ats_pct_non_div',
            'fieldGroup' : 'ATS Splits',
            'displayType' : 'BettingAts',
            'displayParams' : {'percision' : 1},
        },
        {
            'displayName' : 'Playoffs',
            'refName' : 'ats_pct_playoff',
            'fieldGroup' : 'ATS Splits',
            'displayType' : 'BettingAts',
            'displayParams' : {'percision' : 1},
        },


    ]

    const defaultSort = [{
        'field' : 'wins',
        'direction' : 'dsc'
    }]


    // data //
    const data = useStaticQuery(graphql`
        query {
            allCoachesCsv {
                nodes {
                    coach
                    seasons
                    teams
                    games
                    wins
                    losses
                    ties
                    is_active
                    win_pct
                    games_playoff
                    wins_playoff
                    ties_playoff
                    games_superbowl
                    wins_superbowl
                    playoff_births
                    ats_pct
                    ats_return
                    ats_roi
                    avg_pf
                    avg_pa
                    avg_margin
                    avg_spread
                    pfr_coach_image_url
                    ats_pct_home
                    ats_pct_away
                    ats_pct_playoff
                    ats_pct_favorite
                    ats_pct_underdog
                    ats_pct_bye
                    ats_pct_div
                    ats_pct_non_div
                }
            }
        }      
    `)
    
    // unique values for filtering //
    const coaches = []
    const teams = []

    data.allCoachesCsv.nodes.forEach((row) => {

        if (!coaches.includes(row['coach'])) {
            coaches.push(row['coach'])
        }

        // extract teams form json //
        const teamJsonArray = eval(row['teams'])
        if (teamJsonArray) {

            teamJsonArray.forEach((rec) => {

                const parsedRec = JSON.parse(rec)

                if (!teams.includes(parsedRec['team'])) {
                    teams.push(parsedRec['team'])
                }
            })
        }

    })

    // translate into dropdown options //
    const coachOptions = coaches.map((row) => {
        return (
            {'value' : row, 'label' : row}
        )
    })

    const teamOptions = teams.map((row) => {
        return (
            {'value' : row, 'label' : row}
        )
    })

    const statusOptions = [
        {'value': '1', 'label' : 'Active'},
        {'value': '0', 'label' : 'Inactive'}
    ]

    // drop states //
    // in logic, if array is blank, then we will take all //
    const [selectedCoaches, setSelectedCoaches] = useState([])
    const [selectedTeams, setSelectedTeams] = useState([])
    const [selectedGames, setSelectedGames] = useState('')
    const [selectedStatus, setSelectedStatus] = useState([{'value': '1', 'label' : 'Active'}])

    const updateGames = event => {
        setSelectedGames(event.target.value)
    }
    // filter data //
    // first create arrays of selected values that can be checked //
    // as we iterate over the dataset //

    // teams //
    const coachVals = selectedCoaches.map((coach) => {
        return(
            coach['value']
        )
    })

    const teamVals = selectedTeams.map((team) => {
        return(
            team['value']
        )
    })

    const statusVals = selectedStatus.map((status) => {
        return(
            status['value']
        )
    })

    const filteredData = data.allCoachesCsv.nodes.filter((row) => {

        // boolean to toggle if row is included //
        var includeBool = 1

        // coaches //
        if (coachVals.length === 0 || coachVals.includes(row['coach'])) {

        } else {
            includeBool = includeBool * 0
        }

        // teams //
        // counter to hold matches //
        var teamCounter = 0

        // again parse out vals //
        const teamJsonArray = eval(row['teams'])
        if (teamJsonArray) {
            teamJsonArray.forEach((rec) => {

                const parsedRec = JSON.parse(rec)
                
                if (teamVals.includes(parsedRec['team'])) {
                    teamCounter = teamCounter + 1
                }
            })
        }
    
        if (teamVals.length === 0 || teamCounter > 0) {

        } else {
            includeBool = includeBool * 0
        }
        
        // status //
        if (statusVals.length === 0 || statusVals.includes(row['is_active'])) {

        } else {
            includeBool = includeBool * 0
        }

        // games //
        if (isNaN(parseInt(selectedGames))) {

        } else if (parseInt(row['games']) < parseInt(selectedGames)) {
            includeBool = includeBool * 0
        }

        // if bool still 1 after filters, then add //
        if (includeBool === 1) {
            return (true)
        } else {
            return (false)
        }

    })

    const tableFilters = [
        {
            'filterId' : 'status',
            'filterName' : 'Status',
            'filterType' : 'multiSelect',
            'filterParams' : {
                'options' : statusOptions,
                'onChange' : setSelectedStatus,
                'values' : selectedStatus,
                'default' : null
            }
        },
        {
            'filterId' : 'teams',
            'filterName' : 'Teams',
            'filterType' : 'multiSelect',
            'filterParams' : {
                'options' : teamOptions,
                'onChange' : setSelectedTeams,
                'values' : selectedTeams,
                'default' : null
            }
        },
        {
            'filterId' : 'coaches',
            'filterName' : 'Coaches',
            'filterType' : 'multiSelect',
            'filterParams' : {
                'options' : coachOptions,
                'onChange' : setSelectedCoaches,
                'values' : selectedCoaches,
                'default' : null
            }
        },
        {
            'filterId' : 'minGames',
            'filterName' : 'Min. Games',
            'filterType' : 'numInput',
            'filterParams' : {
                'values' : selectedGames,
                'onChange' : updateGames
            }
        },
    ]

    return (

        <div className={coachStyles.team_container}>
            <TopTableBar
                tableData={filteredData}
                ignores={['teams', 'pfr_coach_image_url']}
                fileName={'coaches.csv'}
                filters={tableFilters}
            />
            <div className={coachStyles.team_table_container}>
                <SortableTable
                    records={filteredData}
                    fields={tempFields}
                    plateType={'coachPlate'}
                    defaultSort={defaultSort}
                    linkType={'none'}
                    idField={'coach'}
                />    
            </div>
        </div>
    )
}

export default CoachTable