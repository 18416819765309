import React from "react"
import Layout from '../components/layout'
import Head from '../components/head'
import CoachTable from "../components/Table/Tables/coach_table"

import recStyles from "../components/receiver_box_sort.module.scss"



const Coaches = () => {

    return (
        <Layout>
            <Head
                title="NFL Head Coaches"
                pageDescription="NFL head coaching records, records against the spread, and ATS return from 1999 to present"
            />
            <div className={recStyles.title_box}>
                <h1 className={recStyles.title}>
                    NFL Head Coaches
                </h1>
                <h2 className={recStyles.sub_title}>
                    NFL head coaching records, records against the spread, and ATS return from 1999 to present
                </h2>
            </div>
            <CoachTable/>
            <div className={recStyles.content_container}>
                <h3 className={recStyles.content_h2}>
                    NFL Head Coach Records and Records Against the Spread
                </h3>
                <p className={recStyles.content_p}>
                    NFL head coaches are a critical factor for the success of any team during the season. While their past success in the regular seasons, playoffs, and against betting market expectations (ATS) may not be the most useful statistics towards predicting the future, they are fun to compare and and analyze. The table above allows you to compare coaching accolades like Super Bowls, Conference Championships, regular seasons wins, average points scored, ATS records, ATS splits, and more.<br className={recStyles.content_break}/>
                    Below, we'll explore some fun findings from the data.
                </p>
                <h3 className={recStyles.content_h3}>
                    Top NFL Head Coaches Against the Spread (ATS)
                </h3>
                <p className={recStyles.content_p}>
                    If the market really understood the value of coaching, you might expect all coaches to be around 50% ATS and for ATS record not to correlate much winning percentage. The skill of great coaches should be priced in! However, what we see instead is that the top coaches by ATS percent are some of the best coaches of the last two decades. Jim Harbaugh's impressive run with the 49ers generated a staggering 60% ATS record. Had you blindly bet on the 49er's every game during his tenure, you would have returned 12 units (15.5% of risked capital).<br className={recStyles.content_break}/>
                    Small sample sizes can produce these kind of results, which is why Bill Belichick's 57.4% against the spread over 400+ games is an even more impressive accomplishment. Blindly betting on the Patriots over Belichick's tenure would have netted you 43 units (and a few Super Bowls!).<br className={recStyles.content_break}/>
                    Some other Hall of Fame caliber coaches also sit close to the top of list. Andy Reid, Mary Schottenheimer, Sean Payton, Bill Cowher, Tony Dungy, and Brian Billick are all top 20 coaches against the spread and produced positive returns for those who bet on them.
                </p>
                <h3 className={recStyles.content_h3}>
                    Home vs. Road NFL Head Coach ATS Records
                </h3>
                <p className={recStyles.content_p}>
                    Home and away record splits contain similarly interesting datapoints as some coaches really do perform differently at home versus on the road. Of head coaches who coached at least 75 games, Dick Vermeil has the best home record against the spread, coming in at 63%. However, Vermeil was completely average on the road covering in just 53% of appearances. <br className={recStyles.content_break}/>
                    Andy Reid, Bill Cowher, Marty Schottenheimer, and Jim Haslett all covered 60% or more of their road games, but only Schottenheimer is even above 50% at home. Haslett is the worst of the bunch, covering just 37% of his home appearances.<br className={recStyles.content_break}/>
                    Based on our previous home field advantage work, these numbers likely reflect nothing more than random variance.
                </p>
                <h3 className={recStyles.content_h3}>
                    Underdogs and Favorites: How NFL Head Coaches Perform in Different Scenarios
                </h3>
                <p className={recStyles.content_p}>
                    Of all the splits bettors like to cite when it comes to NFL head coach ATS records is performance as a favorite vs as an underdog. Mike Tomlin has long been known as coach who gets the most out of his team. This notion is bourn out in the data, where he leads all coaches with a cover rate of 64% as an underdog. The next best coach as an underdog? Well, that just happens to be the coach Tomlin replaced -- former Steelers great Bill Cowher. Before we start wondering whether or not their putting something in the water up in Pittsburg, consider that the remaining list of NFL head coaches who cover over 60% of their games as an underdog is a litany of hall of famers and future hall of famers. Sean Payton, Bill Belichick, Andy Reid, John Harbaugh, Tony Dungy, and Kyle Shanahan all met this mark. Perhaps it's best not to doubt a good head coach.
                </p>
                <h3 className={recStyles.content_h3}>
                    Postseason Success: Head Coaches' ATS Records in Playoff Games
                </h3>
                <p className={recStyles.content_p}>
                    Given the small sample sizes, ATS records in playoff games provides easily the wildest (and least meaningful) splits. Doug Pederson is currently the best when it comes to playoff ATS, having covered 88% of games. This compares to just 53.3% during the regular season, and is driven largely by the unexpected success he had with the Philadelphia Eagles during their historical Super Bowel run.<br className={recStyles.content_break}/>
                    If we do want to make an attempt at adjusting for sample size, we can look at the ATS record of coaches who achieved at least 10 playoff births. With this filtering, John Harbaugh holds the top spot with a 65% ATS record followed by Mike McCarthy and his 60% ATS record. Perhaps those Aaron Rodgers years with the Green Bay Packers were a bit better than we remember?
                </p>
                <h3 className={recStyles.content_h3}>
                    Good Coaches Win, Great Coaches Cover
                </h3>
                <p className={recStyles.content_p}>
                    If the saying "good coaches win, while great coaches cover" is true, then there is no coach greater than Dick Jauron. Of coaches with a career losing record, Jauron is the only coach with a positive record against the spread, as he covered in 53.5% of his appearances.<br className={recStyles.content_break}/>
                    Here's to you Dick. Bettors of the world thank you for your service.
                </p>
                <h3 className={recStyles.content_h2}>
                    Frequently Asked Questions
                </h3>
                <h3 className={recStyles.content_h3}>
                    Who are the current NFL coaches?
                </h3>
                <p className={recStyles.content_p}>
                    Being an NFL head coach is a volatile profession, which makes it difficult to keep a blog post up to date with a list of current NFL coaches. Luckily, the table above has a designation for whether or not a coach is active that will update each week. Even if a coach is fired mid-season, the table will be able to tell you who is currently active.
                </p>
                <h3 className={recStyles.content_h3}>
                    Who is the greatest head coach in NFL history?
                </h3>
                <p className={recStyles.content_p}>
                    Don Shula is often regarded as the greatest head coach in NFL history, boasting six Super Bowl titles, two AFL championships, and a perfect season in his 33-year tenure. However, not everyone agrees.<br className={recStyles.content_break}/>
                    Many, including this author believe Bill Belichick is the greatest coach of all time (and not just because the dataset only goes back to 1999!). While Shula's resume is impressive, it was accomplished in a different era. The game was less professionalized than it is today, meaning an outstanding talent like Shula could more easily maintain an edge over his peers. Furthermore, the league rules, particularly around salary cap were much friendlier to dynastic franchises. In the modern era, things like the salary cap make it difficult to field consistently elite teams year in and year out. While Shula had counterparts in Chuck Noll, Tom Landry ,and Bill Walsh who achieved similar stretches of dominance, there is no equal to Belichick in modern NFL history.
                </p>
                <h3 className={recStyles.content_h3}>
                    Which NFL coach has the longest tenure?
                </h3>
                <p className={recStyles.content_p}>
                    George Halas had the longest-tenured NFL career, which spanned a monumental 40 years as a head coach. Given turnover at the coaching position in today's game, it's difficult to imagine this record being challenged anytime soon. Bill Belichick and Andy Reid have the best shot as each have coached for over two decades.
                </p>
                <h3 className={recStyles.content_h3}>
                    Which NFL Head Coaches have multiple Super Bowls?
                </h3>
                <p className={recStyles.content_p}>
                    As of this writing, nine NFL head coaches had won multiple Super Bowls: Bill Belichick (x6), Chuck Noll (x4), Bill Walsh (x3), Joe Gibbs (x3) Andy Reid (x2), Vince Lombardi (x2), Tom Flores (x2), Jimmy Johnson (x2), George Seifert (x2), Mike Shanahan (x2), Tom Coughlin (x2), Bill Parcells (x2), Tom Landry (x2), and Don Shula (x2).
                </p>
                <h3 className={recStyles.content_h3}>
                    Which NFL Coach has the most wins?
                </h3>
                <p className={recStyles.content_p}>
                    The all-time leader in wins is Don Shula with 328, followed by George Halas with 318. However, with 298 wins and, Bill Belichick looks primed to challenge Shula for the most all time. Since Tom Brady left the Patriots, Belichick has averaged about 8 to 9 wins per season. At this rate, Belichick would need to coach just 4 more seasons to catch Shula.
                </p>
            </div>
        </Layout>
    )
}

export default Coaches